"use client";
import Link from "next/link";
import Header from "./Header";

export default function NotFound() {
  return (
    <>
      <Header
        altText={`test`}
        title={`Not Found`}
        paragraph={`Could not find requested resource`}
      />
      <div className="standard-page">
        <div>
          <h1>Our apologies! It seems the content you are looking for has been moved. </h1>
          <p>The good news is you can find it by visiting our home page at the link below.</p>
          <Link href="/">Return Home</Link>
        </div>
      </div>
    </>
  );
}
